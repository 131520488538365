function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { MILLISECONDS_IN, ORDERED_UNITS, UNITS } from "./constants";
export var getLowestUnit = function getLowestUnit(displayUnits) {
  return _toConsumableArray(ORDERED_UNITS).reverse().find(function (unit) {
    return displayUnits[unit];
  }) || UNITS.milliseconds;
};
export var splitMillisecondsIntoUnits = function splitMillisecondsIntoUnits(milliseconds, displayUnits) {
  var lowestUnit = getLowestUnit(displayUnits);
  var remainder = milliseconds % MILLISECONDS_IN[lowestUnit];
  if (2 * remainder >= MILLISECONDS_IN[lowestUnit]) {
    // if the remainder is large, add enough seconds to increse the lowest unit
    milliseconds += MILLISECONDS_IN[lowestUnit] - remainder;
  }
  var units = {};
  ORDERED_UNITS.forEach(function (unit) {
    if (displayUnits[unit]) {
      units[unit] = Math.floor(milliseconds / MILLISECONDS_IN[unit]);
      milliseconds -= units[unit] * MILLISECONDS_IN[unit];
    }
  });
  return units;
};
export var isValidNumber = function isValidNumber(value) {
  return typeof value === "number" && isFinite(value);
};
export var getDurationMaxDisplayUnits = function getDurationMaxDisplayUnits(_ref) {
  var milliseconds = _ref.milliseconds,
    _ref$maxDisplayUnits = _ref.maxDisplayUnits,
    maxDisplayUnits = _ref$maxDisplayUnits === void 0 ? ORDERED_UNITS.length : _ref$maxDisplayUnits;
  var displayUnits = {};
  if (!isValidNumber(milliseconds)) {
    return displayUnits;
  }
  var _iterator = _createForOfIteratorHelper(ORDERED_UNITS),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var unit = _step.value;
      if (Object.keys(displayUnits).length >= maxDisplayUnits) {
        break;
      }

      // @ts-expect-error - stupid typescript isn't smart enough to check the isValidNumber check above ¯\_(ツ)_/¯
      var millisecondsByUnit = splitMillisecondsIntoUnits(milliseconds, {
        days: true,
        hours: true,
        minutes: true,
        seconds: true,
        milliseconds: true
      });

      // @ts-expect-error - stupid typescript isn't smart enough to check the isValidNumber check above ¯\_(ツ)_/¯
      if (milliseconds > MILLISECONDS_IN[unit] && millisecondsByUnit[unit] > 0) {
        displayUnits[unit] = true;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return displayUnits;
};