function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
export var COMPARE_OBJECTS = true;
export var MEMO_CACHE_SIZE = 10;
export var UNITS = {
  days: "days",
  hours: "hours",
  minutes: "minutes",
  seconds: "seconds",
  milliseconds: "milliseconds"
};
export var MILLISECONDS_IN = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, UNITS.days, 1 * 1000 * 60 * 60 * 24), UNITS.hours, 1 * 1000 * 60 * 60), UNITS.minutes, 1 * 1000 * 60), UNITS.seconds, 1 * 1000), UNITS.milliseconds, 1);
export var ORDERED_UNITS = [UNITS.days, UNITS.hours, UNITS.minutes, UNITS.seconds, UNITS.milliseconds];

// Duration props defaults
export var DEFAULT_DISPLAY = "narrow";
export var DEFAULT_DISPLAY_UNITS = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, UNITS.days, true), UNITS.hours, true), UNITS.minutes, true), UNITS.seconds, true), UNITS.milliseconds, false);
export var DEFAULT_LOCALE = "en-US";
export var DEFAULT_MILLISECONDS = null;