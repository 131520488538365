"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typography = exports.space = exports.shadows = exports.radii = exports.lineHeights = exports.fontWeights = exports.fontSizes = exports.fontFamily = exports.easing = exports.duration = exports.default = exports.breakpoints = exports.borders = exports.borderWidths = void 0;
var _polished = require("polished");
var _seedsColor = _interopRequireDefault(require("@sproutsocial/seeds-color"));
var _seedsNetworkcolor = _interopRequireDefault(require("@sproutsocial/seeds-networkcolor"));
var _seedsTypography = _interopRequireDefault(require("@sproutsocial/seeds-typography"));
var _seedsSpace = _interopRequireDefault(require("@sproutsocial/seeds-space"));
var _seedsDepth = _interopRequireDefault(require("@sproutsocial/seeds-depth"));
var _seedsMotion = _interopRequireDefault(require("@sproutsocial/seeds-motion"));
var _seedsBorder = _interopRequireDefault(require("@sproutsocial/seeds-border"));
var _literalColors = _interopRequireDefault(require("./literal-colors"));
var _datavizPalette = require("./dataviz-palette");
var _decorativePalettes = require("./decorative-palettes");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /* eslint-disable no-restricted-imports */ // eslint-disable-next-line no-restricted-syntax
// json-to-scss is not working with the alias right now
//import {TypeThemeMode} from '@src/types/theme';
var breakpoints = exports.breakpoints = ["900px", "1200px", "1500px", "1800px"];
var MODE = "light";

// If you are making changes to the colors in the theme file tag the Design Systems team on your PR! Thank you!!

var colors = _objectSpread(_objectSpread({
  app: {
    background: {
      base: _seedsColor.default.COLOR_NEUTRAL_100
    }
  },
  container: {
    background: {
      base: _seedsColor.default.COLOR_NEUTRAL_0,
      success: _decorativePalettes.green.background,
      warning: _decorativePalettes.yellow.background,
      error: _decorativePalettes.red.background,
      info: _decorativePalettes.blue.background,
      opportunity: _decorativePalettes.purple.background,
      /**  @deprecated Use "error" instead of "danger" */
      danger: _decorativePalettes.red.background,
      decorative: {
        green: _decorativePalettes.green.background,
        blue: _decorativePalettes.blue.background,
        purple: _decorativePalettes.purple.background,
        yellow: _decorativePalettes.yellow.background,
        orange: _decorativePalettes.orange.background,
        red: _decorativePalettes.red.background,
        neutral: _decorativePalettes.neutral.background,
        magenta: _decorativePalettes.magenta.background,
        pink: _decorativePalettes.pink.background,
        aqua: _decorativePalettes.aqua.background,
        teal: _decorativePalettes.teal.background
      },
      selected: _seedsColor.default.COLOR_NEUTRAL_800,
      positive_sentiment: _seedsColor.default.COLOR_BLUE_500,
      negative_sentiment: _seedsColor.default.COLOR_RED_500,
      neutral_sentiment: _seedsColor.default.COLOR_NEUTRAL_300
    },
    border: {
      base: _seedsColor.default.COLOR_NEUTRAL_200,
      success: _decorativePalettes.green.highlight,
      warning: _decorativePalettes.yellow.highlight,
      error: _decorativePalettes.red.highlight,
      /**  @deprecated Use "error" instead of "danger" */
      danger: _decorativePalettes.red.highlight,
      info: _decorativePalettes.blue.highlight,
      opportunity: _decorativePalettes.purple.highlight,
      decorative: {
        green: _decorativePalettes.green.highlight,
        blue: _decorativePalettes.blue.highlight,
        purple: _decorativePalettes.purple.highlight,
        yellow: _decorativePalettes.yellow.highlight,
        orange: _decorativePalettes.orange.highlight,
        red: _decorativePalettes.red.highlight,
        neutral: _decorativePalettes.neutral.highlight,
        magenta: _decorativePalettes.magenta.highlight,
        pink: _decorativePalettes.pink.highlight,
        aqua: _decorativePalettes.aqua.highlight,
        teal: _decorativePalettes.teal.highlight
      },
      selected: _seedsColor.default.COLOR_NEUTRAL_800
    }
  },
  button: {
    primary: {
      background: {
        base: _seedsColor.default.COLOR_BLUE_700,
        hover: _seedsColor.default.COLOR_BLUE_800,
        active: _seedsColor.default.COLOR_BLUE_900
      },
      border: {
        base: "transparent"
      },
      text: {
        base: _seedsColor.default.COLOR_NEUTRAL_0,
        hover: _seedsColor.default.COLOR_NEUTRAL_0
      }
    },
    secondary: {
      background: {
        base: "transparent",
        hover: _seedsColor.default.COLOR_NEUTRAL_800,
        active: _seedsColor.default.COLOR_NEUTRAL_900
      },
      border: {
        base: _seedsColor.default.COLOR_NEUTRAL_800
      },
      text: {
        base: _seedsColor.default.COLOR_NEUTRAL_800,
        hover: _seedsColor.default.COLOR_NEUTRAL_0
      }
    },
    pill: {
      background: {
        base: "transparent",
        hover: _seedsColor.default.COLOR_NEUTRAL_100,
        active: _seedsColor.default.COLOR_NEUTRAL_200
      },
      border: {
        base: "transparent",
        hover: _seedsColor.default.COLOR_NEUTRAL_800
      },
      text: {
        base: _seedsColor.default.COLOR_NEUTRAL_800,
        hover: _seedsColor.default.COLOR_NEUTRAL_900
      }
    },
    destructive: {
      background: {
        base: _seedsColor.default.COLOR_RED_800,
        hover: _seedsColor.default.COLOR_RED_900,
        active: _seedsColor.default.COLOR_RED_1000
      },
      border: {
        base: "transparent"
      },
      text: {
        base: _seedsColor.default.COLOR_NEUTRAL_0,
        hover: _seedsColor.default.COLOR_NEUTRAL_0
      }
    },
    placeholder: {
      background: {
        base: "transparent",
        hover: _seedsColor.default.COLOR_NEUTRAL_0,
        active: _seedsColor.default.COLOR_NEUTRAL_0
      },
      border: {
        base: _seedsColor.default.COLOR_NEUTRAL_500
      },
      text: {
        base: _seedsColor.default.COLOR_BLUE_700,
        hover: _seedsColor.default.COLOR_BLUE_800
      }
    },
    unstyled: {
      background: {
        base: "transparent"
      },
      border: {
        base: "transparent"
      },
      text: {
        base: _seedsColor.default.COLOR_NEUTRAL_700,
        hover: _seedsColor.default.COLOR_NEUTRAL_900
      }
    }
  },
  link: {
    base: _seedsColor.default.COLOR_BLUE_800,
    hover: _seedsColor.default.COLOR_BLUE_900
  },
  text: {
    headline: _seedsColor.default.COLOR_NEUTRAL_900,
    subtext: _seedsColor.default.COLOR_NEUTRAL_700,
    body: _seedsColor.default.COLOR_NEUTRAL_800,
    inverse: _seedsColor.default.COLOR_NEUTRAL_0,
    error: _decorativePalettes.red.foreground,
    background: {
      highlight: _seedsColor.default.COLOR_YELLOW_200,
      selection: _seedsColor.default.COLOR_BLUE_300
    },
    decorative: {
      green: _decorativePalettes.green.foreground,
      blue: _decorativePalettes.blue.foreground,
      purple: _decorativePalettes.purple.foreground,
      yellow: _decorativePalettes.yellow.foreground,
      orange: _decorativePalettes.orange.foreground,
      red: _decorativePalettes.red.foreground,
      neutral: _decorativePalettes.neutral.foreground,
      magenta: _decorativePalettes.magenta.foreground,
      pink: _decorativePalettes.pink.foreground,
      aqua: _decorativePalettes.aqua.foreground,
      teal: _decorativePalettes.teal.foreground
    }
  },
  icon: {
    base: _seedsColor.default.COLOR_NEUTRAL_800,
    inverse: _seedsColor.default.COLOR_NEUTRAL_0,
    success: _decorativePalettes.green.foreground,
    warning: _decorativePalettes.yellow.foreground,
    error: _decorativePalettes.red.foreground,
    /**  @deprecated Use "error" instead of "danger" */
    danger: _decorativePalettes.red.foreground,
    info: _decorativePalettes.blue.foreground,
    opportunity: _decorativePalettes.purple.foreground,
    applied: _seedsColor.default.COLOR_BLUE_700,
    positive_sentiment: _seedsColor.default.COLOR_BLUE_600,
    negative_sentiment: _seedsColor.default.COLOR_RED_600,
    neutral_sentiment: _seedsColor.default.COLOR_NEUTRAL_500
  },
  form: {
    background: {
      base: _seedsColor.default.COLOR_NEUTRAL_0,
      selected: _seedsColor.default.COLOR_NEUTRAL_800
    },
    border: {
      base: _seedsColor.default.COLOR_NEUTRAL_600,
      error: _decorativePalettes.red.highlight,
      warning: _decorativePalettes.yellow.highlight,
      selected: _seedsColor.default.COLOR_NEUTRAL_800
    },
    placeholder: {
      base: _seedsColor.default.COLOR_NEUTRAL_700
    }
  },
  listItem: {
    background: {
      base: "transparent",
      hover: _seedsColor.default.COLOR_NEUTRAL_100,
      selected: _seedsColor.default.COLOR_NEUTRAL_800
    }
  },
  overlay: {
    background: {
      base: (0, _polished.transparentize)(0.44, _seedsColor.default.COLOR_NEUTRAL_1000)
    },
    text: {
      base: _seedsColor.default.COLOR_NEUTRAL_0
    },
    icon: {
      base: _seedsColor.default.COLOR_NEUTRAL_0
    }
  },
  elevation: {
    base: "".concat(_seedsColor.default.COLOR_NEUTRAL_900, "3D")
  },
  illustration: {
    fill: _seedsColor.default.COLOR_BLUE_400,
    stroke: _decorativePalettes.neutral.foreground
  },
  network: {
    twitter: _seedsNetworkcolor.default.NETWORK_COLOR_TWITTER,
    twitter_like: _seedsNetworkcolor.default.NETWORK_COLOR_TWITTER_LIKE,
    facebook: _seedsNetworkcolor.default.NETWORK_COLOR_FACEBOOK,
    facebook_audience_network: _seedsNetworkcolor.default.NETWORK_COLOR_FACEBOOK_AUDIENCE_NETWORK,
    linkedin: _seedsNetworkcolor.default.NETWORK_COLOR_LINKEDIN,
    instagram: _seedsNetworkcolor.default.NETWORK_COLOR_INSTAGRAM,
    feedly: _seedsNetworkcolor.default.NETWORK_COLOR_FEEDLY,
    analytics: _seedsNetworkcolor.default.NETWORK_COLOR_ANALYTICS,
    youtube: _seedsNetworkcolor.default.NETWORK_COLOR_YOUTUBE,
    messenger: _seedsNetworkcolor.default.NETWORK_COLOR_MESSENGER,
    snapchat: _seedsNetworkcolor.default.NETWORK_COLOR_SNAPCHAT,
    pinterest: _seedsNetworkcolor.default.NETWORK_COLOR_PINTEREST,
    tumblr: _seedsNetworkcolor.default.NETWORK_COLOR_TUMBLR,
    reddit: _seedsNetworkcolor.default.NETWORK_COLOR_REDDIT,
    tripadvisor: _seedsNetworkcolor.default.NETWORK_COLOR_TRIPADVISOR,
    glassdoor: _seedsNetworkcolor.default.NETWORK_COLOR_GLASSDOOR,
    google_my_business: _seedsNetworkcolor.default.NETWORK_COLOR_GOOGLE_MY_BUSINESS,
    google_business_messages: _seedsNetworkcolor.default.NETWORK_COLOR_GOOGLE_BUSINESS_MESSAGES,
    google_play_store: _seedsNetworkcolor.default.NETWORK_COLOR_GOOGLE_PLAY_STORE,
    apple_app_store: _seedsNetworkcolor.default.NETWORK_COLOR_APPLE_APP_STORE,
    salesforce: _seedsNetworkcolor.default.NETWORK_COLOR_SALESFORCE,
    zendesk: _seedsNetworkcolor.default.NETWORK_COLOR_ZENDESK,
    hubspot: _seedsNetworkcolor.default.NETWORK_COLOR_HUBSPOT,
    microsoft_dynamics: _seedsNetworkcolor.default.NETWORK_COLOR_MICROSOFT_DYNAMICS,
    yelp: _seedsNetworkcolor.default.NETWORK_COLOR_YELP,
    whatsapp: _seedsNetworkcolor.default.NETWORK_COLOR_WHATSAPP,
    tiktok: _seedsNetworkcolor.default.NETWORK_COLOR_TIKTOK,
    threads: _seedsNetworkcolor.default.NETWORK_COLOR_THREADS,
    trustpilot: _seedsNetworkcolor.default.NETWORK_COLOR_TRUSTPILOT,
    x: _seedsNetworkcolor.default.NETWORK_COLOR_X,
    x_like: _seedsNetworkcolor.default.NETWORK_COLOR_X_LIKE
  },
  dataviz: {
    map: _datavizPalette.datavizPalette.DATAVIZ_COLORS_MAP,
    list: _datavizPalette.datavizPalette.DATAVIZ_COLORS_LIST,
    placeholder: _datavizPalette.datavizPalette.PLACEHOLDER
  },
  userStatus: {
    online: _seedsColor.default.COLOR_GREEN_700,
    offline: _seedsColor.default.COLOR_RED_600,
    unavailable: _seedsColor.default.COLOR_NEUTRAL_600
  }
}, _literalColors.default), _datavizPalette.datavizPalette);
var typography = exports.typography = {
  100: _seedsTypography.default.TYPOGRAPHY_SIZE_100,
  200: _seedsTypography.default.TYPOGRAPHY_SIZE_200,
  300: _seedsTypography.default.TYPOGRAPHY_SIZE_300,
  400: _seedsTypography.default.TYPOGRAPHY_SIZE_400,
  500: _seedsTypography.default.TYPOGRAPHY_SIZE_500,
  600: _seedsTypography.default.TYPOGRAPHY_SIZE_600,
  700: _seedsTypography.default.TYPOGRAPHY_SIZE_700,
  800: _seedsTypography.default.TYPOGRAPHY_SIZE_800,
  900: _seedsTypography.default.TYPOGRAPHY_SIZE_900,
  1000: _seedsTypography.default.TYPOGRAPHY_SIZE_1000,
  1100: _seedsTypography.default.TYPOGRAPHY_SIZE_1100,
  1200: _seedsTypography.default.TYPOGRAPHY_SIZE_1200
};
var fontSizes = exports.fontSizes = {
  100: _seedsTypography.default.TYPOGRAPHY_SIZE_100.fontSize,
  200: _seedsTypography.default.TYPOGRAPHY_SIZE_200.fontSize,
  300: _seedsTypography.default.TYPOGRAPHY_SIZE_300.fontSize,
  400: _seedsTypography.default.TYPOGRAPHY_SIZE_400.fontSize,
  500: _seedsTypography.default.TYPOGRAPHY_SIZE_500.fontSize,
  600: _seedsTypography.default.TYPOGRAPHY_SIZE_600.fontSize,
  700: _seedsTypography.default.TYPOGRAPHY_SIZE_700.fontSize,
  800: _seedsTypography.default.TYPOGRAPHY_SIZE_800.fontSize,
  900: _seedsTypography.default.TYPOGRAPHY_SIZE_900.fontSize,
  1000: _seedsTypography.default.TYPOGRAPHY_SIZE_1000.fontSize,
  1100: _seedsTypography.default.TYPOGRAPHY_SIZE_1100.fontSize,
  1200: _seedsTypography.default.TYPOGRAPHY_SIZE_1200.fontSize
};
var lineHeights = exports.lineHeights = {
  100: _seedsTypography.default.TYPOGRAPHY_SIZE_100.lineHeight,
  200: _seedsTypography.default.TYPOGRAPHY_SIZE_200.lineHeight,
  300: _seedsTypography.default.TYPOGRAPHY_SIZE_300.lineHeight,
  400: _seedsTypography.default.TYPOGRAPHY_SIZE_400.lineHeight,
  500: _seedsTypography.default.TYPOGRAPHY_SIZE_500.lineHeight,
  600: _seedsTypography.default.TYPOGRAPHY_SIZE_600.lineHeight,
  700: _seedsTypography.default.TYPOGRAPHY_SIZE_700.lineHeight,
  800: _seedsTypography.default.TYPOGRAPHY_SIZE_800.lineHeight,
  900: _seedsTypography.default.TYPOGRAPHY_SIZE_900.lineHeight,
  1000: _seedsTypography.default.TYPOGRAPHY_SIZE_1000.lineHeight,
  1100: _seedsTypography.default.TYPOGRAPHY_SIZE_1100.lineHeight,
  1200: _seedsTypography.default.TYPOGRAPHY_SIZE_1200.lineHeight
};
var fontFamily = exports.fontFamily = _seedsTypography.default.TYPOGRAPHY_FAMILY;
var fontWeights = exports.fontWeights = {
  normal: _seedsTypography.default.TYPOGRAPHY_WEIGHT_NORMAL,
  semibold: _seedsTypography.default.TYPOGRAPHY_WEIGHT_SEMIBOLD,
  bold: _seedsTypography.default.TYPOGRAPHY_WEIGHT_BOLD,
  extrabold: _seedsTypography.default.TYPOGRAPHY_WEIGHT_EXTRA_BOLD
};
var space = exports.space = {
  0: _seedsSpace.default.SPACE_SIZE_0,
  100: _seedsSpace.default.SPACE_SIZE_100,
  200: _seedsSpace.default.SPACE_SIZE_200,
  300: _seedsSpace.default.SPACE_SIZE_300,
  350: _seedsSpace.default.SPACE_SIZE_350,
  400: _seedsSpace.default.SPACE_SIZE_400,
  450: _seedsSpace.default.SPACE_SIZE_450,
  500: _seedsSpace.default.SPACE_SIZE_500,
  600: _seedsSpace.default.SPACE_SIZE_600
};
var radii = exports.radii = {
  400: _seedsBorder.default.BORDER_RADIUS_400,
  500: _seedsBorder.default.BORDER_RADIUS_500,
  600: _seedsBorder.default.BORDER_RADIUS_600,
  900: _seedsBorder.default.BORDER_RADIUS_900,
  1000: _seedsBorder.default.BORDER_RADIUS_1000,
  inner: _seedsBorder.default.BORDER_RADIUS_500,
  outer: _seedsBorder.default.BORDER_RADIUS_600,
  pill: _seedsBorder.default.BORDER_RADIUS_1000
};
var borders = exports.borders = {
  500: "".concat(_seedsBorder.default.BORDER_WIDTH_500, " solid"),
  600: "".concat(_seedsBorder.default.BORDER_WIDTH_600, " solid"),
  700: "".concat(_seedsBorder.default.BORDER_WIDTH_700, " solid")
};
var borderWidths = exports.borderWidths = {
  500: _seedsBorder.default.BORDER_WIDTH_500,
  600: _seedsBorder.default.BORDER_WIDTH_600,
  700: _seedsBorder.default.BORDER_WIDTH_700
};
var shadows = exports.shadows = {
  low: "".concat(_seedsDepth.default.ELEVATION_LEVEL_100, " ").concat(_seedsColor.default.COLOR_NEUTRAL_900, "3D"),
  medium: "".concat(_seedsDepth.default.ELEVATION_LEVEL_300, " ").concat(_seedsColor.default.COLOR_NEUTRAL_900, "3D"),
  high: "".concat(_seedsDepth.default.ELEVATION_LEVEL_400, " ").concat(_seedsColor.default.COLOR_NEUTRAL_900, "3D")
};
var easing = exports.easing = {
  ease_in: _seedsMotion.default.MOTION_EASE_IN,
  ease_out: _seedsMotion.default.MOTION_EASE_OUT,
  ease_inout: _seedsMotion.default.MOTION_EASE_INOUT
};
var duration = exports.duration = {
  fast: _seedsMotion.default.MOTION_DURATION_FAST,
  medium: _seedsMotion.default.MOTION_DURATION_MEDIUM,
  slow: _seedsMotion.default.MOTION_DURATION_SLOW
};
var theme = {
  breakpoints: breakpoints,
  colors: colors,
  typography: _objectSpread(_objectSpread({}, typography), {}, {
    typography: typography
  }),
  fontSizes: fontSizes,
  lineHeights: lineHeights,
  fontFamily: fontFamily,
  fontWeights: fontWeights,
  space: _objectSpread(_objectSpread({}, space), {}, {
    space: space,
    "-space": Object.keys(space).reduce(function (negativeSpace, key) {
      // The values are strings (eg 24px) so we concatenate a negative sign
      // @ts-ignore: no explicit any
      negativeSpace[key] = "-".concat(space[key]);
      return negativeSpace;
    }, {})
  }),
  radii: _objectSpread(_objectSpread({}, radii), {}, {
    radii: radii
  }),
  borders: borders,
  borderWidths: borderWidths,
  shadows: shadows,
  easing: easing,
  duration: duration,
  mode: MODE
};
var _default = exports.default = theme;